<template>
  <div class="row mx-0 justify-content-center" >
    <div class="col-12 mb-3 text-center font19" >
      Donor Details
    </div>
    <div class="col-12 mb-3 text-center" >
      You're making this donation as:
    </div>
    <UserDisplay :userDetails="value" :imageUrl="imageUrl" :sadaqahUserDonatingType="sadaqahUserDonatingType" />
    <div class="col-10 my-4 text-center divider">
    </div>
    <ChangeDonor @updateDonor="setUserDetails()" :sadaqahUserDonatingType="sadaqahUserDonatingType" />
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    ChangeDonor: defineAsyncComponent(() => import('./views/ChangeDonor.vue')),
    UserDisplay: defineAsyncComponent(() => import('./views/UserDisplay.vue'))
  },
  name: 'User Details',
  props: ['modelValue', 'sadaqahUserDonatingType'],
  emits: ['update:modelValue'],
  data () {
    return {
      imageUrl: null
    }
  },
  async mounted () {
    await this.setUserDetails()
  },
  computed: {
    ...mapGetters([
      'user', 'donateAs'
    ]),
    value: {
      get () {
        return this.modelValue
      },
      set (val) {
        this.$emit('update:modelValue', val)
      }
    }
  },
  methods: {
    setUserDetails () {
      console.log('setUserDetails')
      if (this.donateAs) {
        if (this.sadaqahUserDonatingType === 'organisation') {
          this.value.organisationID = this.donateAs.userID
        } else {
          this.value.userID = this.donateAs.userID
        }
        this.value.address.addressLine1 = this.donateAs.postalAddress1 ? this.donateAs.postalAddress1 : null
        this.value.address.town = this.donateAs.town ? this.donateAs.town : null
        this.value.address.country = (this.donateAs.country && this.donateAs.country.description) ? this.donateAs.country.description : null
        this.value.address.postalCode = this.donateAs.postalcode ? this.donateAs.postalcode : null
        this.value.organisationName = this.donateAs.organisation ? this.donateAs.organisation : null
        this.value.donorName = this.donateAs.firstName ? this.donateAs.firstName : null
        this.value.donorSurname = this.donateAs.lastName ? this.donateAs.lastName : null
        this.value.cellNumber = this.donateAs.cell ? this.donateAs.cell : null
        this.value.email = this.donateAs.email ? this.donateAs.email : null
        this.imageUrl = this.donateAs.imageUrl ? this.donateAs.imageUrl : null
      } else if (this.user) {
        this.value.userID = this.user.userID ? this.user.userID : null
        this.value.address.addressLine1 = this.user.postalAddress1 ? this.user.postalAddress1 : null
        this.value.address.town = this.user.town ? this.user.town : null
        this.value.address.country = (this.user.country && this.user.country.description) ? this.user.country.description : null
        this.value.address.postalCode = this.user.postalcode ? this.user.postalcode : null
        this.value.organisationName = this.user.organisation ? this.user.organisation : null
        this.value.donorName = this.user.firstName ? this.user.firstName : null
        this.value.donorSurname = this.user.lastName ? this.user.lastName : null
        this.value.cellNumber = this.user.cell ? this.user.cell : null
        this.value.email = this.user.email ? this.user.email : null
        this.imageUrl = this.user.imageUrl ? this.user.imageUrl : null
      }
    }
  }
}
</script>
<style scoped>
.divider {
  border-bottom: 2px solid var(--green-color-dark);
}
</style>
